import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { ExternalLink } from "react-feather"

import { Section } from "../common/layout/Containers"
import { FeatureText /*GetStartedContainer */ } from "./sectionsStyles"
import HomeShowCase from "../common/HomeShowCase"
import Reviews from "../reviews/Reviews"
import { Container } from "../common/layout/Containers"

const Book = ({ page }) => {
  const { book, nadine, sandra, djamina } = useStaticQuery(query)
  const image = getImage(book.childImageSharp.gatsbyImageData)
  const reviewers = [
    {
      name: "Lindsay-Nadine Benoit",
      title: `Pasteure louange 
      à l’église Gospelvie (Montréal)`,
      message: `Je veux prendre le temps de recommander le livre de Sammantha Thom,
        une femme passionnée avec un grand cœur qui outille et guide les
        croyants dans le domaine relationnel notamment au niveau du mariage.
        Wow ! Les célibataires vous ne serez pas déçues par les sujets, les
        enseignements bibliques et les prières que renferme ce livre pour le
        choix de votre futur partenaire. Mon souhait est que lors de votre
        lecture, le Saint-Esprit vous inspire ; qu’il parle à votre cœur
        durant ces 31 jours, car Dieu veut participer à votre processus de
        choix du mari. Dieu ne veut pas que vous échouiez ! Au contraire,
        c’est un plan de réussite et de bonheur qu’il désire pour vous.
        Bonne lecture et soyez encouragées. God Bless !`,
      image: getImage(nadine.childImageSharp.gatsbyImageData),
    },
    {
      name: "Djamina Goldman",
      title: "Pasteure jeunes adultes à l'Église Nouvelle Vie (Longueuil).",
      message: `Le livre 31 Jours de prière pour ton futur mari de Sammantha Thom a été un vrai délice pour mon âme. Son livre a surpassé mes attentes. J’ai savouré chaque page de son livre du début jusqu’à la fin. J’ai été émue, j’ai parfois versé quelques larmes en lisant les histoires, les témoignages et en adressant à Dieu les différentes prières. J’ai pris le temps de lire un chapitre par jour ; et chaque matin, j’avais toujours hâte au prochain chapitre.
      Son livre a été écrit avec beaucoup de sagesse, de discernement, d’authenticité et de vulnérabilité de cœur. Il n’y a pas de doute : Dieu utilise Sammantha pour impacter une génération à avoir un mariage épanoui et centré sur Dieu. Le titre de son livre pourrait définitivement être modifié, car il serait aussi bénéfique et approprié pour les hommes, et même pour les couples mariés.
      Je vous recommande fortement ce livre ! C’est l’un des plus beaux cadeaux que vous pourriez offrir à toute personne qui aspire au mariage.`,
      image: getImage(djamina.childImageSharp.gatsbyImageData),
    },
    {
      name: "Sandra",
      title: "Sandrabyfaith (YouTube)",
      message: `Plus qu’un livre, c’est un guide qui nous conduit à prier pour notre
      futur époux et notre futur mariage, selon la volonté de Dieu. De
      plus, bien qu’il s’agisse principalement de prier pour notre futur
      époux, tout au long de la lecture, on est emmenée personnellement à
      se questionner sur nous-mêmes en tant que future épouse.
      Correspondons-nous nous-mêmes aux critères que nous voulons trouver
      chez notre futur époux ? J’ai pris beaucoup de plaisir à lire ce
      livre, et j’ai particulièrement apprécié le fait qu’il soit
      christocentrique du début à la fin et que l’audience soit clairement
      ciblée et identifiée dès le départ. Je le conseille vivement et sans
      l’ombre d’une réserve.`,
      image: getImage(sandra.childImageSharp.gatsbyImageData),
    },
  ]
  return (
    <Section id="livre" anchor>
      <HomeShowCase
        image={image}
        fileName="vers le mari de ma destinée"
        title="31 jours de prière pour ton futur mari"
        page={page}
      >
        <FeatureText>
          Les résultats d’un sondage sur les critères du choix du bon mari,
          effectué par l’auteure auprès de femmes célibataires chrétiennes, ont
          révélé que leur préoccupation principale était : « comment reconnaitre
          la bonne personne ».
        </FeatureText>
        <FeatureText>
          <strong>{`31 jours de prière pour ton futur mari `}</strong> est un
          guide pratique de prière, inspiré par le Saint-Esprit, qui répond
          clairement à cette question. En effet, en s’appuyant sur des principes
          bibliques, l’auteure emmène la lectrice à disposer son cœur à recevoir
          le dessein de mariage que Dieu a préparé pour elle et à renouveler son
          intelligence quant aux qualités d’un bon mari selon Dieu.
        </FeatureText>
        <FeatureText>
          Contrairement à plusieurs ouvrages qui ont été publiés avant lui, ce
          livre ne « sert » pas que de la théorie ou des phrases toutes faites
          qui ne répondent pas véritablement aux besoins de la célibataire
          chrétienne. Mais, il donne des outils pratiques, apporte de précieux
          témoignages, exprime des remarques pertinentes et conduit dans la
          prière fervente qui est d’une grande efficacité. C’est le parfait
          combo pour non seulement reconnaitre ton futur mari, mais aussi te
          préparer à rentrer dans le plan parfait de mariage que le Seigneur a
          formé pour toi.
        </FeatureText>
      </HomeShowCase>
      <Container style={{ marginTop: "4rem" }}>
        <Wrapper id="buyBook">
          <BuyButton
            href="https://maisonbible.ca/nouveaux-produits/31-jours-de-priere-pour-ton-futur-mari-un-c-ur-dispose-et-une-intelligence-renouvelee-9782982020214"
            target="_blank"
          >
            <span>
              Acheter à la Maison de la Bible Montreal
              <ExternalLinkCustom size={20} />
            </span>
          </BuyButton>
          <BuyButton
            href="https://www.amazon.com/dp/2982020211"
            target="_blank"
          >
            <span>
              Acheter sur Amazon
              <ExternalLinkCustom size={20} />
            </span>
          </BuyButton>
        </Wrapper>
      </Container>
      <Reviews
        reviews={reviewers}
        large
        title="Recommandations"
        time={30000}
        id="bookReview"
      ></Reviews>
    </Section>
  )
}

export default Book

const query = graphql`
  {
    book: file(
      sourceInstanceName: { eq: "book" }
      name: { eq: "book-show-case-with-text-green-center-2560x1440" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
    nadine: file(
      sourceInstanceName: { eq: "reviewers" }
      name: { eq: "lindsay-nadine" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
    sandra: file(
      sourceInstanceName: { eq: "reviewers" }
      name: { eq: "sandra" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
    djamina: file(
      sourceInstanceName: { eq: "reviewers" }
      name: { eq: "djamina-goldman" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
  }
`

const Wrapper = styled.div`
  padding: 80px 0;
  margin-top: -80px;
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: space-around;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const BuyButton = styled.a`
  align-items: center;
  display: flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  justify-content: center;
  white-space: pre-wrap;
  text-align: center;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
    background: ${(props) => props.theme.color.over};
    transition: 0.3s;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin: 1rem 0;
  }

  span svg {
    vertical-align: bottom;
  }
`

const ExternalLinkCustom = styled(ExternalLink)`
  margin-left: 0.2rem;
`
