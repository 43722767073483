import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import Review from "./Review"

// const delay = 7000

const Slideshow = ({ reviews, large, time }) => {
  const [index, setIndex] = useState(0)
  const timeoutRef = useRef(null)

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
        ),
      time
    )
    return () => {
      resetTimeout()
    }
  }, [index, reviews.length, time])

  return (
    <SlideShow large={large}>
      <SlideShowSlider
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {reviews.map((item, index) => (
          <Slide key={index}>
            <Review
              name={item.name}
              title={item.title}
              content={item.message}
              stars={item.rating}
              image={item.image}
            />
          </Slide>
        ))}
      </SlideShowSlider>
      <SlideShowDots>
        {reviews.map((_, idx) => (
          <SlideShowDot
            key={idx}
            onClick={() => {
              setIndex(idx)
            }}
            style={{
              backgroundColor: `${index === idx ? `#6b460b` : ""}`,
            }}
          ></SlideShowDot>
        ))}
      </SlideShowDots>
    </SlideShow>
  )
}

export default Slideshow

const SlideShow = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: ${(props) => (props.large ? "80%" : "30rem")};
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    max-width: 100%;
  }
  //   padding-bottom: 2rem;
`

const SlideShowSlider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`

const Slide = styled.div`
  display: inline-table;
  height: 200px;
  width: 100%;
  border-radius: 40px;
`

/* Buttons */
const SlideShowDots = styled.div`
  text-align: center;
`

const SlideShowDot = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
  &:active {
    background-color: #6a0dad;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    height: 15px;
    width: 15px;
  }
`
