import React from "react"
import { Star } from "react-feather"
import styled from "styled-components"

const starsNumber = 5
const Rating = ({ stars }) => {
  const geRating = (stars) => {
    let startDecreasing = stars
    const ratings = []
    for (let i = 0; i < starsNumber; i++) {
      ratings.push(
        <li key={i}>
          <Icon
            size={30}
            color={`${startDecreasing > 0 ? "#6b460b" : "grey"} `}
            fill={`${startDecreasing > 0 ? "#6b460b" : "none"} `}
          />
        </li>
      )
      startDecreasing--
    }
    return ratings
  }

  return <RatingList>{geRating(stars)}</RatingList>
}

export default Rating

const RatingList = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0;
  li {
    margin-right: 0.5rem;
  }
`

const Icon = styled(Star)``
