import * as React from "react"

import Layout from "../components/common/layout/Layout"
import Author from "../components/sections/Author"
import Hero from "../components/sections/Hero"
import Book from "../components/sections/Book"
import EBook from "../components/sections/EBook"
import SubscribeForm from "../components/sections/SubscribeForm"
// import SubscribeForm2 from "../components/sections/SubscribeForm2"

import Seo from "../components/common/SEO"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Le voyage vers le mari de ma destinée" />
      <Hero />
      <Book />
      <EBook />
      <Author />
      <SubscribeForm />
      {/* <SubscribeForm2 /> */}
    </Layout>
  )
}

export default IndexPage
