import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { Section } from "../common/layout/Containers"
import { FeatureText, GetStartedContainer, LinkStyled } from "./sectionsStyles"
import HomeShowCase from "../common/HomeShowCase"
import Reviews from "../reviews/Reviews"

const EBook = ({ page }) => {
  const {
    file,
    allContentfulReview: { nodes: reviewList },
  } = useStaticQuery(query)
  const image = getImage(file.childImageSharp.gatsbyImageData)
  return (
    <Section id="e-book" anchor>
      <HomeShowCase
        image={image}
        fileName="Ebook Les critères d'un bon mari de de Sammantha Thom"
        title="Les critères d'un bon mari"
        // titleSpan="Les critères d'un bon mari"
        page={page}
      >
        <FeatureText>
          Si tu es une femme célibataire qui aspire au mariage, tu t’es déjà̀
          probablement demandé quelles sont les qualités du mari idéal. Il est
          même possible que tu aies dressé une liste exhaustive des critères
          auxquels ton futur époux doit répondre, et tu as bien raison ! C’est
          avec cet homme que tu passeras le reste de ta vie ; par conséquent, tu
          ne peux pas te permettre de faire le mauvais choix. Dans ce livre,
          Sammantha Thom t’invite à examiner les critères d’un bon mari et les
          prières de plus de 100 épouses chrétiennes à la lumière de la Parole
          de Dieu, et à dresser ta propre liste (ou corriger l’ancienne) de
          qualités du mari idéal.
        </FeatureText>
      </HomeShowCase>
      <GetStartedContainer>
        <LinkStyled to="/#subscribe">Obtenir l'Ebook gratuitement</LinkStyled>
      </GetStartedContainer>
      <Reviews
        reviews={reviewList}
        title="Ce que disent les lecteurs de l'ebook"
        time={10000}
        id="ebookReview"
      ></Reviews>
    </Section>
  )
}

export default EBook

const query = graphql`
  {
    file(
      sourceInstanceName: { eq: "book" }
      name: { eq: "les-criteres-d-un-bon-mari-2560x1440" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
    allContentfulReview(
      filter: { book: { eq: "Les critères d'un bon mari" } }
      sort: { order: DESC, fields: rating }
    ) {
      nodes {
        name
        message
        rating
      }
    }
  }
`
