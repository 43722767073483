import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { ExternalLink } from "react-feather"
// import { Link } from "gatsby"

import { Container, Section } from "../common/layout/Containers"
import { FeatureText } from "./sectionsStyles"
import Socials from "../SocialList"

const Header = () => {
  return (
    <StyledSection id="auteure" anchor author style={{ paddingBottom: "40px" }}>
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>À propos de l'auteure</h1>
            <FeatureText>
              Sammantha Thom est une épouse, une mère et une entrepreneure
              chrétienne qui a expérimenté la puissance restauratrice de Dieu
              dans tous les domaines de sa vie, et surtout sur le plan
              émotionnel. Après avoir longtemps rejeté l’idée du mariage, à
              cause des souffrances et des humiliations passées, elle s’est
              finalement abandonnée à Dieu et elle l’a laissé écrire son
              histoire d’amour avec son mari. Une histoire d’amour qui a été
              entendue par des dizaines de milliers de personnes sur sa chaine
              YouTube.
            </FeatureText>
            <FeatureText>
              Remplie de compassion pour les femmes, qui font face à toutes
              sortes de défis, et passionnée par la guérison émotionnelle,
              Sammantha a créé le ministère{" "}
              <CustomLink
                href="https://jeveillesurmoncoeur.com"
                target="_blank"
                rel="noreferrer"
              >
                Veille sur ton cœur
                <ExternalLinkCustom size={20} />
              </CustomLink>{" "}
              qui aide les femmes à se défaire de leurs blessures, de leurs
              offenses et de leurs tragédies.
            </FeatureText>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage>
              <StaticImage
                src="../../assets/images/book/author.jpg"
                alt="photo de l'auteur du livre 31 jours de prière pour ton futur mari"
                // imgStyle={{ borderRadius: "100%" }}
                placeholder="tracedSVG"
              />
              <div>
                <Socials center />
              </div>
            </StyledImage>
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </StyledSection>
  )
}

export default Header

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 100%);
  }
`
const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    // margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1.7fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.div`
  width: 300px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 300px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
  }
`

const ExternalLinkCustom = styled(ExternalLink)`
  margin-left: 0.2rem;
`

const CustomLink = styled.a`
  svg {
    vertical-align: middle;
  }
`
