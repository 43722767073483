import React from "react"
import styled from "styled-components"
import Rating from "./Rating"
import { GatsbyImage } from "gatsby-plugin-image"

import { FeatureText } from "../sections/sectionsStyles"

const Review = ({ content, name, title, stars, image }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {stars && <Rating stars={stars} />}
      <FeatureText style={{ whiteSpace: "normal" /*padding: "0 2rem"*/ }}>
        {content}
      </FeatureText>

      {image ? (
        <FooterWapper>
          <span>
            <ImageWrapper
              style={{ borderRadius: "100%", position: "relative", zIndex: 0 }}
            >
              <GatsbyImage image={image} alt={name} />
            </ImageWrapper>
          </span>
          <NameContainer>
            <span
              style={{
                color: "#6b460b",
                fontFamily: `${(props) => props.theme.font.medium}`,
              }}
            >
              {name}
            </span>
            <small
              style={{
                marginTop: "0.30rem",
                whiteSpace: "pre-line",
                textAlign: "left",
              }}
            >
              {title}
            </small>
          </NameContainer>
        </FooterWapper>
      ) : (
        <span
          style={{
            color: "#6b460b",
            fontFamily: `${(props) => props.theme.font.medium}`,
          }}
        >
          {name}
        </span>
      )}
    </div>
  )
}

export default Review

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 1rem;
`

const FooterWapper = styled.footer`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
`

const NameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  font-weight: 600;
  max-width: 20rem;
  align-items: flex-start;
`
