import React from "react"
import styled from "styled-components"

import { Container } from "../common/layout/Containers"
import Slideshow from "./SlideShow"
import { SectionTitle } from "../sections/sectionsStyles"

const ReviewList = ({ reviews, large, title, time }) => {
  return (
    <StyledContainer>
      <SectionTitle style={{ marginTop: "5rem" }}>{title}</SectionTitle>
      <Slideshow reviews={reviews} large={large} time={time} />
    </StyledContainer>
  )
}

export default ReviewList

const StyledContainer = styled(Container)`
  margin-bottom: 6rem;
`

// const StyledSection = styled(Section)`
//   //   background-color: ${(props) => props.theme.color.background.light};
// `

// const Ul = styled.ul`
//   text-align: center;
//   align-items: center;
//   display: flex;
//   list-style: none;
//   margin: 0;
//   overflow: hidden;
// `

// const UlItem = styled.li`
//   align-items: center;
//   color: white;
//   display: flex;
//   justify-content: center;
//   margin: 0;
//   padding: 3rem 1rem;
//   transform: translateX(-0%);
//   width: 100%;
//   flex-shrink: 0;
//   transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
//   box-shadow: 0 0.1rem 1rem rgb(0 0 0/15%);
//   background-color: ${(props) => props.theme.color.background.secondary};
// `
